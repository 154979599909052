<template>
  <v-container id="mezcal-story">
    <base-card light>
      <v-row
      class="mt-10 mb-5"
      >
        <v-col
          md="12"
          align="center"
        >
        </v-col>
      </v-row>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="7"
          sm="12"
        >
          <h1 class="display-3 font-weight-light mb-5">
            Mezcal
          </h1>

          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.mezcalDefinition"
          >
          </div>
        </v-col>
        <v-col
          md="5"
          sm="12"
        >
          <v-img
            :src="require('@/assets/heads/head3green.png')"
            class="darken-4"
            height="250"
            width="100%"
            contain
          />
        </v-col>
      </v-row>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="6"
          offset-md="3"
          sm="12"
        >
          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.mystic"
          >
          </div>
        </v-col>
      </v-row>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="7"
          sm="12"
        >
          <v-img
            :src="require('@/assets/mezcal/agaves.jpg')"
            class="darken-4"
            width="100%"
            contain
            fill-height
          />
        </v-col>
        <v-col
          md="5"
          sm="12"
        >
          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.plantExplanation"
          >
          </div>
        </v-col>
      </v-row>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="5"
          sm="12"
        >
          <v-img
            :src="require('@/assets/mezcal/barricas_2.jpeg')"
            class="darken-4"
            max-height="600"
            contain
          />
        </v-col>
        <v-col
          md="6"
          sm="12"
        >
          <v-img
            :src="require('@/assets/botellas/stickerBlueLabel_small.jpg')"
            class="darken-4 bm-10"
            max-height="417"
            width="100%"
            contain
          />
          <br />
          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.production"
          ></div>
        </v-col>
      </v-row>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="7"
          sm="12"
        >
          <v-img
            :src="require('@/assets/mezcal/tapon_1.jpg')"
            class="darken-4"
            width="100%"
            contain
            height="400"
          />
        </v-col>
        <v-col
          md="5"
          sm="12"
        >
          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.cork"
          ></div>
        </v-col>
      </v-row>
      <v-row
        class="codex-row"
      >
        <v-img
          :src="require('@/assets/heads/bottleCodex.png')"
          class="darken-4"
          width="100%"
          contain
        />
      </v-row>
      <v-row
        align="center"
      >
        <v-col
          md="10"
          offset-md="1"
          sm="12"
        >
          <div class="subheading pl-2 mb-4 text-justify">
            <p
              v-html="content.bottleHeads"
            >
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="logo-background-white"
      >
      <br>
        <v-img
          :src="require('@/assets/logos/logo_popol_vuh_black.png')"
          height="350px"
          width="100%"
          margin-bottom="50px"
          margin-top="50px"
          contain
        />
        <br>
      </v-row>
      <v-row
        align="center"
      >
        <v-col
          md="5"
          sm="12"
          class="fill-height"
        >
          <v-img
            :src="require('@/assets/botellas/blueLabel_tobala.jpg')"
            class="darken-4"
            height="700px"
            width="100%"
            contain
            fill-height
          />
        </v-col>
        <v-col
          class="text-justify"
          md="7"
          sm="12"
        >
          <p
            v-html="content.bottleIdentity"
          ></p>
          <br>
          <p
            v-html="content.quetzalcoatl"
          ></p>
          <br>
          <p
            v-html="content.tlatoani"
          ></p>
          <p
            v-html="content.endStory"
          ></p>
        </v-col>
      </v-row>
      <v-row
        class="text-center align-center mt-10 mb-5"
      >
        <v-col 
          md="12"
          class="align-center"
        >
          <h1
            class='orange--text'
            v-html="content.ourLegend"
          ></h1>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
  export default {
    name: 'MezcalStory',
    computed: {
      content () {
        return this.$store.state.mezcalstory[this.$store.state.lang]
      },
    },
  }
</script>

<style scoped>
  .p-hr {
    border: 3px solid #3F5465;
    border-radius: 5px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .p-title {
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .logo-background {
    background-color: #3F5465;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .codex-row {
    margin-bottom: 25px;
  }
  #mezcal-story {
    min-height: 3100px;
  }
</style>
